import React from 'react'
import Footer from 'components/Footer'
import Seo from 'components/Seo'
import BlockBanner from 'components/blocks/BlockBanner'
import BlockTitleOnly from 'components/blocks/BlockTitleOnly'
import BlockTabbedGrid from 'components/blocks/BlockTabbedGrid'
import { content, filterContentByLocale } from 'content/content.js'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'

const SolutionsPage = () => {
  const { locale } = useLocalization()
  const localeContent = filterContentByLocale(content, locale)
  const { solutions, footer } = localeContent
  const { banner, titleOnly, tabbedGrid } = solutions

  let seoContent = {
    title: {
      en: `Solutions`,
      zh: `解决方案`,
    },
  }
  seoContent = filterContentByLocale(seoContent, locale)

  return (
    <div>
      <div className='page'>
        <Seo content={seoContent} />
        <div className='bg-gradient'>
          <BlockBanner content={banner} />
          <BlockTitleOnly content={titleOnly} />
          <BlockTabbedGrid content={tabbedGrid} />
          <Footer content={footer.default} />
        </div>
      </div>
    </div>
  )
}

export default SolutionsPage
