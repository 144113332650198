import React from 'react'
import LineHeadline from 'components/LineHeadline'
import Inview from 'components/Inview'

const BlockTitleOnly = ({ content }) => {
  const { section, title } = content
  return (
    <section className='block-title-only'>
      <Inview className='container fade-in up'>
        {section && <LineHeadline text={section} />}
        <h2>{title}</h2>
      </Inview>
    </section>
  )
}

export default BlockTitleOnly
