import React, { useState } from 'react'
import Image from 'components/Image'
import LineHeadline from 'components/LineHeadline'
import Inview from 'components/Inview'
import Select from 'react-select'
import { makeYellow } from 'js/utils'

const BlockTabbedGrid = ({ content }) => {
  const { section, categories, items } = content
  const [activeSlug, setActiveSlug] = useState('all')
  const [activeItems, setActiveItems] = useState(items)

  const newActiveItems = (newActiveSlug) => {
    setActiveSlug(newActiveSlug)
    let newActiveItems = items.filter((item) => item.category === newActiveSlug)
    if (newActiveSlug === 'all') newActiveItems = items
    setActiveItems([])
    setTimeout(() => setActiveItems(newActiveItems), 1)
  }

  const selectOptions = categories.map((category) => {
    return {
      value: category.slug,
      label: category.title,
    }
  })

  const handleSelect = (e) => {
    newActiveItems(e.value)
  }

  return (
    <section className='block-tabbed-grid'>
      <div className='container'>
        <LineHeadline text={section} />
      </div>
      <div className='main'>
        <TabsWrap
          categories={categories}
          activeSlug={activeSlug}
          newActiveItems={newActiveItems}
        />
        <div className='lower'>
          <Inview className='bg fade-in up' />
          <div className='container'>
            <SelectWrap
              selectOptions={selectOptions}
              handleSelect={handleSelect}
            />
            <ExcerptsWrap categories={categories} activeSlug={activeSlug} />
            <GridWrap activeItems={activeItems} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockTabbedGrid

const SelectWrap = ({ selectOptions, handleSelect }) => {
  return (
    <Inview className='select fade-in up'>
      <Select
        className='form--element--select'
        classNamePrefix='react-select'
        options={selectOptions}
        defaultValue={selectOptions[0]}
        onChange={handleSelect}
        isSearchable={false}
      />
    </Inview>
  )
}

const ExcerptsWrap = ({ categories, activeSlug }) => {
  return (
    <div className='excerpts'>
      {categories.map((category, i) => {
        if (category.excerpt) {
          return (
            <Inview
              key={i}
              className={`excerpt fade-in ${
                category.slug === activeSlug ? 'active' : ''
              }`}
            >
              <p>{category.excerpt}</p>
            </Inview>
          )
        }
      })}
    </div>
  )
}

const TabsWrap = ({ categories, activeSlug, newActiveItems }) => {
  return (
    <Inview className='tabs fade-in up'>
      <div className='container'>
        {categories.map((item, i) => (
          <Tab
            key={i}
            content={item}
            activeSlug={activeSlug}
            newActiveItems={newActiveItems}
          />
        ))}
      </div>
    </Inview>
  )
}

const Tab = ({ content, activeSlug, newActiveItems }) => {
  const { slug, icon, title } = content
  return (
    <div
      className={`tab ${slug === activeSlug ? 'active' : ''}`}
      onClick={() => newActiveItems(slug)}
    >
      <div className='icon'>
        <Image className='default' filename={icon} />
        <Image className='active' filename={makeYellow(icon)} />
      </div>
      <h3>{title}</h3>
    </div>
  )
}

const GridWrap = ({ activeItems }) => {
  return (
    <div className='grid'>
      {activeItems.map((item, i) => (
        <GridItem key={i} i={i} content={item} />
      ))}
    </div>
  )
}

const GridItem = ({ content, i }) => {
  const { title, image } = content
  return (
    <Inview className='item'>
      <div className='image-wrap'>
        <div className='image screen'>
          <Image
            className={`item fade-in up ${
              i <= 5 ? `stagger-${i * 100}` : 'stagger-500'
            }`}
            filename={image}
          />
        </div>
        <div className='image full'>
          <Image
            className={`item fade-in up ${
              i <= 5 ? `stagger-${i * 100}` : 'stagger-500'
            }`}
            filename={image}
          />
        </div>
      </div>
      <h4
        className={`item fade-in up ${
          i <= 5 ? `stagger-${i * 100}` : 'stagger-500'
        }`}
      >
        {title}
      </h4>
    </Inview>
  )
}
